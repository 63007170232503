import classes from '../styles/Oversigt.module.css'

export function numberWithCommas(num: number) {
    var x = num.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x))
        x = x.replace(pattern, "$1.$2");
    return x;
}

export default function Oversigt(props: { yearOversigt: Record<string, number>; }): JSX.Element {


    return (
        <div className={classes.oversigtContainer}>
            <div className={classes.title}>Årsoversigt</div>
            <div className={classes.content}>
                {props.yearOversigt &&
                    (Object.keys(props.yearOversigt).map((key) => {
                        if(props.yearOversigt[key] != 0){
                            return (
                                <div key={key} className={classes.priceContainer}>
                                    <div>Resultat for {key}</div>
                                    <div>{numberWithCommas(props.yearOversigt[key])},- kr</div>
                                </div>
                            )
                        }

                    }))
                }

            </div>

        </div>
    )
}