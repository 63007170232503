import * as React from 'react';
import { useEffect, useState } from 'react';
import { TextField } from '@fluentui/react/lib/TextField';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import "moment-timezone";
import { getAllCourseRegistrations, getAllPeoplenetCourseRegistrations, getAllPeoplenetCoursePrices } from '../api';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Modal,
  Spinner,
  Toggle,
  initializeIcons
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import "../styles/regTable.css";
import moment from 'moment';
import { SagsbehandlerDialog } from './SagsbehandlerDialog';
import Oversigt, { numberWithCommas } from './Oversigt';
import PriceDisplay from './PriceDisplay';
import { DeleteRegistrationDialog } from './DeleteRegistrationDIalog';

initializeIcons();


export interface ICourseRegistration {
  seid: number;
  seSubject: string;
  seDateTime: Date;
  seBody: string;
  seStatus: string;
  seBehandler: string;
  date: string;
  title: string;
  firma: string;
  price: string;
  heardFrom: string;
  deltagere: string;
  week: number;
  year: number;
  firstname: string;
  lastname: string;
  company: string;
  address: string;
  zip: string;
  phone: string;
  email: string;
  comments: string;
  coursedateId: string;
  coursedate: string;
  coursePrice: string;
  courseLocation: string;
  participants: string;
  position: string;
  cvr: string;
  klippekort: string;
  postnummer: string;
}

export function RegistrationTable() {
  const { instance, accounts } = useMsal();
  const [allRegistrations, setAllRegistrations] = useState<[]>([]);
  const [filteredRegistrations, setFilteredRegistrations] = useState<ICourseRegistration[]>([]);
  const [registrationsByYear, setRegistrationsByYear] = useState<Record<string, Record<string, ICourseRegistration[]>>>();
  const [oversigtInfo, setOversigtinfo] = useState<{}>({});
  const [currentRegistration, setCurrentRegistration] = useState<ICourseRegistration>();
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
  const [isModalEditOpen, { setTrue: showEditModal, setFalse: hideEditModal }] = useBoolean(false);
  const [isModalDeleteOpen, { setTrue: showDeleteModal, setFalse: hideDeleteModal }] = useBoolean(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [token, setToken] = useState<string>("");
  const [isPeopleNet, setIsPeopleNet] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  var peoplenetMissingPrice: number = 41000;
  var price4D: number = 0;
  var pricePeopleNet: number = 0;
  var yearPrice: number = 0;

  useEffect(() => {
    setIsLoading(true);
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0]
    }).then(async (response) => {

      const result: any = await getAllCourseRegistrations(response.accessToken);
      const peoplenetRegTest: any = await getAllPeoplenetCourseRegistrations();
      const coursePrices: any = await getAllPeoplenetCoursePrices();
      let sortedPeoplenet = [];
      if (peoplenetRegTest.data && peoplenetRegTest.data.length > 0) {
        sortedPeoplenet = peoplenetRegTest.data.map((obj: any) => {
          let price = obj.coursePrice ? trimPriceString(obj.coursePrice, obj) : 0;
          let year = moment(obj.createdTime).weekYear().toString() + " ";

          if (!obj.coursePrice) {
            if (obj.courseTitle.includes("Power Automate") && !obj.courseTitle.includes("Power Apps")) {
              price = "13500";
            } else if (obj.courseTitle.includes("Microsoft Azure FinOps")) {
              price = "18800";
            } else if (year == "2023 ") {
              price = coursePrices.data.find((cp: any) => obj.courseTitle.includes(cp.title))?.price;
            }
          }

          return {
            ...obj,
            seid: obj.id,
            title: obj.courseTitle,
            heardFrom: obj.referredby,
            deltagere: getPeoplenetDeltagere(obj),
            participants: obj?.participants,
            position: obj?.position,
            date: new Date(obj.createdTime),
            week: "Uge " + moment(obj.createdTime).isoWeek().toString(),
            year: year,
            firma: "PeopleNet",
            seStatus: obj.status,
            seBehandler: obj.manager,
            price: price,
            cvr: obj.cvr,
            postnummer: obj.poNumner,
            klippekort: obj.klippekort
          }
        })
      }

      let sortedDates = [];
      if (result && result.data.length > 0) {
        sortedDates = result.data.map((obj: any) => {
          return {
            ...obj,
            date: new Date(obj.seDateTime),
            firma: "4D",
            title: getSECourseName(obj.seBody),
            price: getSECoursePrice(obj.seBody),
            heardFrom: getSECourseHeardFrom(obj.seBody),
            deltagere: getSECoursedeltagere(obj.seBody),
            week: "Uge " + moment(obj.seDateTime).isoWeek().toString(),
            year: moment(obj.seDateTime).weekYear().toString() + " "
          }
        })
      }


      const allRegistrations: any = [...sortedPeoplenet, ...sortedDates];
      const sortedAllRegistrations: [] = allRegistrations.sort((a: any, b: any) => { if (a && b) return b.date - a.date });

      const regSet: Record<string, Record<string, ICourseRegistration[]>> = {};
      const oversigt: Record<string, number> = {};
      //building overview
      oversigt["PeopleNet 2024 "] = peoplenetMissingPrice;
      sortedAllRegistrations.forEach((item: ICourseRegistration) => {

        if (item) {
          //instantiate object year if it's a new year
          if (!regSet[item.year]) {
            regSet[item.year] = {};
          }
          //instantiate objects year if it's a new week
          if (!regSet[item.year][item.week]) {
            regSet[item.year][item.week] = [];
          }
          //update price for the whole year
          if (item.seBody) { //4D

            if (item.price && item.price !== "-") {

              if (!isRegisteredEmailInternal(item.seBody)) { //sum up only if the registration is not internal
                if (oversigt[item.firma + " " + item.year]) {
                  oversigt[item.firma + " " + item.year] += parseFloat(item.price);
                } else {
                  oversigt[item.firma + " " + item.year] = parseFloat(item.price);
                }
              }
            }

          } else if (item.coursePrice || item.firma == "PeopleNet") { //Peoplenet

            var num = typeof (item.price) == "string" ? item.price.replace(/\./g, '') : item.price;

            if (!isRegisteredEmailInternalPeoplenet(item.email ? item.email : item.zip)) {  //sum up only if the registration is not internal

              if (!oversigt[item.firma + " " + item.year]) {
                oversigt[item.firma + " " + item.year] = 0;
              }

              if (item.year >= 2024) {
                if (typeof (num) == "string" && num.indexOf(",") > -1) {
                  const regex = /(?<=Kr)[^,]+/;
                  const match = num.match(regex);
                  if (match) {
                    oversigt[item.firma + " " + item.year] += parseFloat(match[0]);
                  }
                } else {
                  oversigt[item.firma + " " + item.year] += parseFloat(num);
                }

              } else if (item.year == 2023 && num) {
            
                oversigt[item.firma + " " + item.year] += parseInt(num);
              }
            }
          }
          /*    } */

          regSet[item.year][item.week].push(item);
        }
      });


      setAllRegistrations(allRegistrations);
      setFilteredRegistrations(sortedAllRegistrations);
      setToken(response.accessToken);
      setRegistrationsByYear(regSet);
      setOversigtinfo(oversigt);
      setIsLoading(false);

    });
  }, [refresh]);

  const _onFilterRegistrations = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string | undefined): void => {
    yearPrice = 0;
    const search = text?.toLowerCase();
    const filtered = search ? allRegistrations.filter((i: ICourseRegistration) => i.title.toLowerCase().indexOf(search) > -1) : allRegistrations;
    const regSet: Record<string, Record<string, ICourseRegistration[]>> = {};
    const oversigt: Record<string, number> = {};

    filtered.forEach((item: ICourseRegistration) => {


      //instantiate object year if it's a new year
      if (!regSet[item.year]) {
        regSet[item.year] = {};
      }

      //instantiate objects year if it's a new week
      if (!regSet[item.year][item.week]) {
        regSet[item.year][item.week] = [];
      }

      //update price for the whole year
      if (item.seBody) { //4D
        if (item.price && item.price !== "-") {

          if (!isRegisteredEmailInternal(item.seBody)) { //sum up only if the registration is not internal

            if (oversigt[item.firma + " " + item.year]) {
              oversigt[item.firma + " " + item.year] += parseFloat(item.price);
            } else {
              oversigt[item.firma + " " + item.year] = parseFloat(item.price)
            }

          }

        }
      } else if (item.coursePrice) { //Peoplenet

        var num = (item.coursePrice != "-" && item.coursePrice) ? item.coursePrice.replace(/\./g, '') : "0";
        if (!isRegisteredEmailInternalPeoplenet(item.email ? item.email : item.zip)) {  //sum up only if the registration is not internal

          if (oversigt[item.firma + " " + item.year]) {
            oversigt[item.firma + " " + item.year] += parseFloat(num);
          } else {
            if (item.year == 2024) {
              oversigt[item.firma + " " + item.year] = peoplenetMissingPrice + parseFloat(num)
            }

          }


        }
      }

      regSet[item.year][item.week].push(item);

    })
    setFilteredRegistrations(filtered);
    setRegistrationsByYear(regSet);
    setOversigtinfo(oversigt);

  };



  const getSECourseName = (emailBody: string) => {

    if (emailBody.indexOf('Kursus: ') > -1) {
      return emailBody.substring(emailBody.indexOf('Kursus: ') + 7, emailBody.indexOf('<br />Kursus Id:'));
    } else {
      return emailBody.substring(emailBody.indexOf('Test: '), emailBody.indexOf('<br />Test Id:'));
    }

  }
  const getSECoursePrice = (emailBody: string) => {
    if (emailBody.indexOf('Samlet pris: ') > -1) {
      const regex = /Samlet pris: \s*(.*?) kr. ex moms<br /i;
      const match = emailBody.match(regex);
      if (match && match.length > 1) {
        const searchText = match[1].trim();
        return searchText;
      }
    } else {
      return "-";
    }

  }

  const trimPriceString = (price: string, obj: any) => {
    const regex = /(?<=\.)[^,]+/;
    const deltagere = parseInt(getPeoplenetDeltagere(obj));
    if (price) {

      if (price.indexOf(",") <= -1) {
        const filteredPrice = price.replace(/\./g, '');
        const newPrice = parseFloat(filteredPrice) * deltagere;
        return newPrice;
      }
      const match = price.match(regex);
      if (match) {
        const matchPrice = match[0].indexOf(".") > -1 ? match[0].replace(/\./g, '') : match[0];
        const newPrice = parseFloat(matchPrice) * deltagere;
        return newPrice;
      } else {
        const newPrice = parseFloat(price) * deltagere;
        return parseFloat(price) * deltagere;
      }

    } else {
      return "-";
    }

  }

  const getPeoplenetDeltagere = (obj: any) => {
    if (obj?.participants) {
      const regex = /@/g;
      const match = obj.participants.match(regex);

      if (match && match.length >= 1) {
        return match?.length;
      } else {
        return 1;
      }
    } else {
      return 1;
    }
  }


  const getSECourseHeardFrom = (emailBody: string) => {

    if (emailBody.indexOf('Har hørt om 4D fra:') > -1) {

      const regex = /Har hørt om 4D fra:\s*(.*?)<br/i;
      const match = emailBody.match(regex);

      if (match && match.length > 1) {
        const searchText = match[1].trim();

        if (searchText == "Hvor har du hørt om 4D?") {
          return "-"
        }
        return searchText;
      }

    } else {
      return "-"
    }

  }

  const isRegisteredEmailInternal = (emailBody: string) => {
    if (emailBody.indexOf('Email:') > -1) {

      const deltagereStr = emailBody.substring(emailBody.indexOf('Email:') + 6, emailBody.indexOf('<br />Afdeling'));
      const regex = /@([\w-]+)\./;
      const match = deltagereStr.match(regex);

      if (match && match.length >= 1) {

        return match[1] === "peoplenet";
      } else {
        return false
      }

    } else {
      return false
    }

  }
  const isRegisteredEmailInternalPeoplenet = (emailBody: string) => {

    if (emailBody) {

      const regex = /@([\w-]+)/;
      const match = emailBody.match(regex);

      if (match && match.length >= 1) {
        return (match[1] === "people" || match[1] === "peoplenet");
      } else {
        return false
      }

    } else {
      return false
    }

  }

  const getSECoursedeltagere = (emailBody: string) => {

    if (emailBody.indexOf('Deltagere:') > -1) {

      const deltagereStr = emailBody.substring(emailBody.indexOf('Deltagere:') + 10, emailBody.indexOf('<br />Klippekort nr:'));
      const regex = /@/g;
      const match = deltagereStr.match(regex);

      if (match && match.length >= 1) {
        return match?.length;
      } else {
        return "-"
      }

    } else {
      return "-"
    }

  }



  if (isLoading) {
    return <Spinner label='Indlæser...' />
  }

  const renderRegistrationsModal = () => {

    if (currentRegistration) {
      if (currentRegistration.seBody) {
        const htmlString = currentRegistration.seBody;
        // const toRemove = htmlString.substring(htmlString.indexOf('<br />Klippekort nr: '), htmlString.indexOf('<br />Modtag nyheder: '));
        const toRemove2 = htmlString.substring(htmlString.indexOf('<br />Kundetype:'), htmlString.indexOf('<br />Firmanavn:'));
        const toRemove3 = htmlString.substring(htmlString.indexOf('<br />Starttid:'), htmlString.indexOf('<br />Oplyst pris:'));
        var sanitizedString = htmlString.replace(toRemove2, '');
        sanitizedString = sanitizedString.replace(toRemove3, '');

        return (
          <>



            <Modal
              isOpen={isModalOpen}
              onDismiss={hideModal}
              isBlocking={false}
              containerClassName="modalContainer"
            >
              <div className="modalHeader"></div>
              <div className='modalBody' dangerouslySetInnerHTML={{ __html: sanitizedString }}></div>

            </Modal>
          </>
        );
      } else {
        var participants: string = currentRegistration.participants;
        if (currentRegistration.participants) {
          participants = participants.replace(/Slet/g, ' -');

        }

        return (
          <>



            <Modal
              isOpen={isModalOpen}
              onDismiss={hideModal}
              isBlocking={false}
              containerClassName="modalContainer"
            >
              <div className="modalHeader">Bestilling:</div>
              <div className='modalBody'>
                <div>Bestilt af: {currentRegistration.firstname + " " + currentRegistration.lastname} </div>
                <div>Stilling: {currentRegistration?.position}</div>
                <div>Email: {currentRegistration.zip ? currentRegistration.zip : currentRegistration.email}</div>
                <div>Mobil nr: {currentRegistration.phone}</div>
                <div>Firmanavn: {currentRegistration.company}</div>
                <div>Adresse: {currentRegistration.address}</div>
                <br />
                <div>CVR.: {currentRegistration.cvr}</div>
                <div>Klippekort: {currentRegistration.klippekort}</div>
                <div>PO. nummer: {currentRegistration.postnummer}</div>
                <br />
                <div>Deltagere: {currentRegistration?.participants ? <div dangerouslySetInnerHTML={{ __html: participants }}></div> : currentRegistration.firstname + " " + currentRegistration.lastname}</div>
                <br />
                <div>Kursus: {currentRegistration.title}</div>
                <div>Kursus sted: {currentRegistration.courseLocation}</div>
                <div>Kursus dag id: {currentRegistration.coursedateId}</div>
                <div>Kursus dag: {moment(currentRegistration.coursedate).format("DD-MM-YYYY")}</div>
                <div>Pris: {currentRegistration.price} Kr.</div>
                <br />
                <div>Kommentar: {currentRegistration.comments}</div>
              </div>

            </Modal>
          </>
        );
      }

    }
  }



  const _onMoreDetails = (obj: ICourseRegistration) => {
    setCurrentRegistration(obj);
    showModal();

  }
  const _onDeleteRegistration = (obj: ICourseRegistration) => {
    setCurrentRegistration(obj);
    showDeleteModal();

  }

  const sumPrices = (priceToUpdate: number, currentPrice: string | undefined, type: string, item?: any) => {
    if (currentPrice !== undefined && currentPrice !== "-" && type == "4D") {
      price4D = priceToUpdate + parseInt(currentPrice);
    }
    if (currentPrice !== undefined && currentPrice !== "-" && type == "PeopleNet") {
      var num = typeof (currentPrice) == "number" ? currentPrice : currentPrice.replace(/\./g, '');
      pricePeopleNet = priceToUpdate + parseFloat(num);
    }
  }

  const updateTotalPrice = () => {
    price4D = 0;
    pricePeopleNet = 0;
  }

  const _onChange = (ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
    if (checked !== undefined) {
      setIsPeopleNet(checked);
    }

  }

  const _onSetStatus = (item: ICourseRegistration) => {
    setCurrentRegistration(item);
    showEditModal();
  }


  return (
    <div>

      <div className='optionsPanel'>
        <TextField
          styles={{ root: { width: 200 } }}
          label="Filtrer på kursus navn"
          onChange={_onFilterRegistrations}
        />

        <Toggle label="Vis med peoplenet email" checked={isPeopleNet} onText="Ja" offText="Nej" onChange={_onChange} />
      </div>

      <Oversigt yearOversigt={oversigtInfo} />


      {registrationsByYear && filteredRegistrations.length > 0 ? (


        <>

          {Object.keys(registrationsByYear).map((year) => {
            return (
              <div className='yearContainer'>
                <div key={year} style={{ fontSize: "1.4rem", fontWeight: 'bolder' }}>{year}</div>

                {Object.keys(registrationsByYear[year]).map(week => {


                  return (
                    <>
                      <div className='registrationWeek'>{week}</div>
                      <table className='tableContainer'>

                        <thead>
                          <tr>
                            <th>Kursus Navn</th>
                            <th align="left">Firma</th>
                            <th align="left" >Pris</th>
                            <th align="left">Har hørt fra</th>
                            <th align="left">Deltagere</th>
                            <th align="left">Status</th>
                            <th align="left">Sagsbehandler</th>
                            <th align="left">Tilmeldt</th>
                            <th align="right"></th>
                            <th align="right"></th>
                            <th align="right"></th>
                          </tr>
                        </thead>
                        <tbody>

                          {registrationsByYear[year][week].map(item => {


                            if (item.seBody && !isPeopleNet && !isRegisteredEmailInternal(item.seBody)) {

                              sumPrices(price4D, item.price, item.firma, item);

                              return (

                                <tr>

                                  <td align="left" style={{ width: '35vw' }}>{item.title}</td>
                                  <td align="left">{item.firma}</td>
                                  <td align="left" >{item.price} ,- kr</td>
                                  <td align="left" style={{ width: "5px" }}>{item.heardFrom}</td>
                                  <td align="left" style={{ width: "2px" }}>{item.deltagere}</td>
                                  <td align="left">{item.seStatus}</td>
                                  <td align="left">{item.seBehandler}</td>
                                  <td align="left">{moment(item.seDateTime).format("DD-MM-YYYY")}</td>
                                  <td align="right" onClick={ev => _onSetStatus(item)} style={{ cursor: "pointer" }}><EditRoundedIcon className="registrationDetails" style={{ color: 'rgb(25,118,210)' }} /></td>
                                  <td align="right" onClick={ev => _onMoreDetails(item)} style={{ cursor: "pointer" }}><InfoOutlinedIcon className="registrationDetails" style={{ color: 'rgb(25,118,210)' }} /></td>
                                  <td align="right" onClick={ev => _onDeleteRegistration(item)} style={{ cursor: "pointer" }}><DeleteIcon className="registrationDetails" style={{ color: 'rgb(25,118,210)' }} /></td>
                                </tr >

                              )

                            } else if (item.seBody && isPeopleNet) {


                              sumPrices(price4D, item.price, item.firma, item);

                              return (

                                <tr>
                                  <td align="left" style={{ width: '35vw' }}>{item.title}</td>
                                  <td align="left">{item.firma}</td>
                                  <td align="left">{item.price} ,- kr</td>
                                  <td align="left" style={{ width: '5vw' }}>{item.heardFrom}</td>
                                  <td align="left" style={{ width: "2px" }}>{item.deltagere}</td>
                                  <td align="left">{item.seStatus}</td>
                                  <td align="left">{item.seBehandler}</td>
                                  <td align="left">{moment(item.seDateTime).format("DD-MM-YYYY")}</td>
                                  <td align="right" onClick={ev => _onSetStatus(item)} style={{ cursor: "pointer" }}><EditRoundedIcon className="registrationDetails" style={{ color: 'rgb(25,118,210)' }} /></td>
                                  <td align="right" onClick={ev => _onMoreDetails(item)} style={{ cursor: "pointer" }}><InfoOutlinedIcon className="registrationDetails" style={{ color: 'rgb(25,118,210)' }} /></td>
                                  <td align="right" onClick={ev => _onDeleteRegistration(item)} style={{ cursor: "pointer" }}><DeleteIcon className="registrationDetails" style={{ color: 'rgb(25,118,210)' }} /></td>
                                </tr >

                              )
                            } else if (isPeopleNet && !item.seBody) {

                              sumPrices(pricePeopleNet, item.price, item.firma, item);

                              return (

                                <tr>

                                  <td align="left" style={{ width: '35vw' }}>{item.title}</td>
                                  <td align="left">{item.firma}</td>
                                  <td align="left">{item.price} ,- kr</td>
                                  <td align="left" style={{ width: '5vw' }}>{item.heardFrom == "16:00:00" ? "-" : item.heardFrom}</td>
                                  <td align="left" style={{ width: "2px" }}>{item.deltagere}</td>
                                  <td align="left">{item.seStatus}</td>
                                  <td align="left">{item.seBehandler}</td>
                                  <td align="left">{moment(item.date).format("DD-MM-YYYY")}</td>
                                  <td align="right" onClick={ev => _onSetStatus(item)} style={{ cursor: "pointer" }}><EditRoundedIcon className="registrationDetails" style={{ color: 'rgb(25,118,210)' }} /></td>
                                  <td align="right" onClick={ev => _onMoreDetails(item)} style={{ cursor: "pointer" }}><InfoOutlinedIcon className="registrationDetails" style={{ color: 'rgb(25,118,210)' }} /></td>
                                  <td align="right" onClick={ev => _onDeleteRegistration(item)} style={{ cursor: "pointer" }}><DeleteIcon className="registrationDetails" style={{ color: 'rgb(25,118,210)' }} /></td>
                                </tr >

                              )

                            } else if (!isPeopleNet && !item.seBody && !isRegisteredEmailInternalPeoplenet((item.email && item.email.indexOf("@") > -1) ? item.email : item.zip)) {

                              sumPrices(pricePeopleNet, item.price, item.firma, item);

                              return (

                                <tr>

                                  <td align="left" style={{ width: '35vw' }}>{item.title}</td>
                                  <td align="left">{item.firma}</td>
                                  <td align="left" >{item.price} ,- kr</td>
                                  <td align="left" style={{ width: '5vw' }}>{item.heardFrom == "16:00:00" ? "-" : item.heardFrom}</td>
                                  <td align="left" style={{ width: "2px" }}>{item.deltagere}</td>
                                  <td align="left">{item.seStatus}</td>
                                  <td align="left">{item.seBehandler}</td>
                                  <td align="left" >{moment(item.date).format("DD-MM-YYYY")}</td>
                                  <td align="right" onClick={ev => _onSetStatus(item)} style={{ cursor: "pointer" }}><EditRoundedIcon className="registrationDetails" style={{ color: 'rgb(25,118,210)' }} /></td>
                                  <td align="right" onClick={ev => _onMoreDetails(item)} style={{ cursor: "pointer" }}><InfoOutlinedIcon className="registrationDetails" style={{ color: 'rgb(25,118,210)' }} /></td>
                                  <td align="right" onClick={ev => _onDeleteRegistration(item)} style={{ cursor: "pointer" }}><DeleteIcon className="registrationDetails" style={{ color: 'rgb(25,118,210)' }} /></td>
                                </tr >

                              )

                            }



                          })}

                        </tbody>
                      </table>

                      <PriceDisplay fourDprice={price4D} peoplenetPrice={pricePeopleNet} formatPrice={numberWithCommas} />
                      {updateTotalPrice()}

                    </>)

                })}

              </div>)
          })}


        </>

      ) : (
        <div className='failedFilter'>Ingen resultater</div>
      )
      }


      {isModalOpen && renderRegistrationsModal()}
      {isModalEditOpen && (
        <SagsbehandlerDialog open={isModalEditOpen} onClose={hideEditModal} registration={currentRegistration} token={token} user={accounts[0]?.name} />
      )}
      {isModalDeleteOpen && (
        <DeleteRegistrationDialog open={isModalDeleteOpen} onClose={hideDeleteModal} registration={currentRegistration} token={token} refresh={setRefresh} refreshState={refresh} />
      )}
    </div >

  );
}
